.calendar-table-container {
  margin: auto;
}

.calendar-day-container {
  text-align: center;
  display: inline-flex;
  width: 46px;
  height: 46px;
  align-items: center;
  justify-content: center;
  position: relative;
}

.calendar-day {
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.calendar-day:hover {
  background-color: #b3a9771f;
}

.calendar-day-selected {
  background-color: #EDF354;
}

.calendar-day-selected:hover {
  background-color: #EDF354;
}

.calendar-day-selected p {
  color: #313300 !important;
}

.calendar-day-today {
  border: 1px solid #EDF354;
}

.calendar-day-today p {
  color: #EDF354 !important;
}

.calendar-day-range-highlight-middle {
  position: absolute;
  z-index: -9;
  background-color: #5C640C;
  color: #DBD8D2 important;
  width: 78px;
  height: 40px;
}

.calendar-day-range-highlight-start {
  position: absolute;
  z-index: -9;
  background-color: #5C640C;
  width: 40px;
  height: 40px;
  left: 24px;
}

.calendar-day-range-highlight-end {
  position: absolute;
  z-index: -9;
  background-color: #5C640C;
  width: 40px;
  height: 40px;
  right: 24px;
}

.calendar-year-item-container {
  text-align: center;
  display: inline-flex;
  width: 98.67px;
  height: 52px;
  align-items: center;
  justify-content: center;
  position: relative;
}

.calendar-year-item {
  cursor: pointer;
  width: 72px;
  height: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.calendar-year-item-selected {
  background-color: #EDF354;
}

.calendar-year-item-selected  p {
  color: #313300 !important;
}

.date-picker-input input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
